import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Mailto from "../components/mailto.jsx";
import { submitContactForm } from "../redux/generalReducer";
import classes from "./contactUs.module.scss";

export default function ContactUs() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialState = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  };
  const [contact, setContact] = useState(initialState);
  const contactus = useSelector((state) => state.general.contactus);

  const handleContact = async (evt) => {
    evt.preventDefault();
    dispatch(submitContactForm(contact));
  };

  useEffect(() => {
    if (contactus.isSuccess) setContact(initialState);
  }, [contactus.isSuccess, dispatch]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Container className={classes.contactUsContainer}>
      <Box className={classes.contactUsInfo}>
        <Typography className={classes.contactUsInfoText}>
          {t("contactus.youMay")}
          <Mailto email="info@avatelevision.ca"></Mailto>
          {t("contactus.or")}
          <Link to="tel:+1(514)312-4747">+1(514)312-4747.</Link>
        </Typography>
        <Typography className={classes.contactUsInfoText}>
          {t("contactus.willContactYou")}
        </Typography>
      </Box>

      <form className={classes.contactUsForm} onSubmit={handleContact}>
        <TextField
          placeholder={t("contactus.name")}
          type="text"
          value={contact.name}
          onChange={(e) => setContact({ ...contact, name: e.target.value })}
          required
          variant="outlined"
        />

        <TextField
          placeholder={t("contactus.email")}
          type="email"
          value={contact.email}
          onChange={(e) => setContact({ ...contact, email: e.target.value })}
          required={!contact.phone}
          variant="outlined"
        />

        <TextField
          placeholder={t("contactus.phone")}
          type="tel"
          value={contact.phone}
          onChange={(e) => setContact({ ...contact, phone: e.target.value })}
          required={!contact.email}
          variant="outlined"
        />

        <TextField
          placeholder={t("contactus.subject")}
          type="text"
          value={contact.subject}
          onChange={(e) => setContact({ ...contact, subject: e.target.value })}
          variant="outlined"
        />

        <TextField
          placeholder={t("contactus.message")}
          type="text"
          value={contact.message}
          onChange={(e) => setContact({ ...contact, message: e.target.value })}
          required
          multiline
          minRows={5}
          variant="outlined"
        />

        {contactus.isLoading && (
          <Typography color="primary">{t("contactus.pleaseWait")}</Typography>
        )}

        {contactus.isSuccess && (
          <Typography className={classes.success}>
            {t("contactus.success")}
          </Typography>
        )}

        {contactus.errorMessage && !contactus.isLoading && (
          <Typography color="error">
            {t(`contactus.${contactus.errorMessage}`)}
          </Typography>
        )}

        <Button
          type="submit"
          variant="contained"
          color="success"
          className={classes.submitBtn}
          disabled={
            contactus.attempts > 3 ||
            contactus.isLoading ||
            contactus.isSuccess ||
            !contact.name ||
            (!contact.email && !contact.phone) ||
            !contact.message
          }
        >
          {t("contactus.send")}
        </Button>
      </form>
    </Container>
  );
}
