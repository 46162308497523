import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import CategoryIcon from "@mui/icons-material/Category";
import CellTowerIcon from "@mui/icons-material/CellTower";
import { Box, Container, Typography } from "@mui/material";
import { useEffect } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classes from "./home.module.scss";

export default function Home() {
  const { t } = useTranslation();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Container className={classes.homeContainer}>
      <Box className={classes.homeMain}>
        <Box className={classes.homeTextsContainer}>
          <Typography gutterBottom className={classes.homeText}>
            {t("home.textp1")}
          </Typography>
          <Typography gutterBottom className={classes.homeText}>
            {t("home.textp2")}
          </Typography>
          <Typography gutterBottom className={classes.homeSubtitle}>
            {t("home.subtitle")}{" "}
            <Link to="ici-live-stream">{t("home.iciTelevision")}</Link>
          </Typography>
        </Box>
        <img className={classes.homeImage} src="/home-bg.svg" alt="home" />
      </Box>
      <Box className={classes.homeSections}>
        <Box className={"classes.castHoursContentsContainer"}>
          <CellTowerIcon
            className={classes.homeSectionsIcons}
            variant="filled"
          />
          <Typography className={"classes.homeBroadcast"}>
            {t("home.broadcast")}
          </Typography>
        </Box>
        <Box className={"classes.castHoursContentsContainer"}>
          <CalendarMonthRoundedIcon
            className={classes.homeSectionsIcons}
            variant="filled"
          />
          <Box className={"classes.homeHours"}>
            <Typography>{t("home.hours.friday")}</Typography>
            <Typography>{t("home.hours.saturday")}</Typography>
            <Typography>{t("home.hours.sunday")}</Typography>
            <Typography>{t("home.hours.monday")}</Typography>
            <Typography>{t("home.hours.tuesday")}</Typography>
            <Typography>{t("home.hours.wednesday")}</Typography>
          </Box>
        </Box>
        <Box className={"classes.programsContainer"}>
          <CategoryIcon
            className={classes.homeSectionsIcons}
            variant="filled"
          />
          <Typography>{t("home.programs.line1")}</Typography>
          <Typography>{t("home.programs.line2")}</Typography>
          <Typography>{t("home.programs.line3")}</Typography>
          <Typography>{t("home.programs.line4")}</Typography>
          <Box></Box>
        </Box>
      </Box>
    </Container>
  );
}
