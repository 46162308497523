import { Box, LinearProgress } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterBar from "../components/filterBar";
import SingleLineGridList from "../components/gridList";
import PagingBtns from "../components/pagingBtns";
import StreamVideo from "../components/streamVideo";
import { fetchArchive } from "../redux/reducer.js";
import classes from "./archive.module.scss";

import ReactGA from "react-ga";

export default function Archive() {
  const dispatch = useDispatch();
  const isArchiveFetched = useSelector((state) => state.archive.isSuccess);

  useEffect(() => {
    if (!isArchiveFetched) {
      dispatch(fetchArchive());
    }
  }, [dispatch, isArchiveFetched]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Box className={classes.archiveContainer}>
      {!isArchiveFetched ? (
        <LinearProgress color="success" />
      ) : (
        <>
          <StreamVideo />
          <FilterBar />
          <SingleLineGridList />
          <PagingBtns />
        </>
      )}
    </Box>
  );
}
