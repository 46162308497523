import { Box, Container, LinearProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchHosts } from "../redux/generalReducer.js";
import classes from "./hosts.module.scss";

export default function Hosts() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const isHostsFechted = useSelector((state) => state.general.hosts.isSuccess);
  const hosts = useSelector((state) => state.general.hosts.hosts);

  useEffect(() => {
    if (!isHostsFechted) {
      dispatch(fetchHosts());
    }
  }, [dispatch, isHostsFechted]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return !isHostsFechted ? (
    <LinearProgress color="success" />
  ) : (
    <Container className={classes.hostsContainer}>
      {hosts.map((host) => (
        <Box className={classes.host} key={host.name}>
          <img className={classes.hostImg} src={host.photo} alt={host.name} />
          <Typography className={classes.hostBio}>
            {i18n.language === "en" ? host.bioen : host.biofr}
          </Typography>
        </Box>
      ))}
    </Container>
  );
}
