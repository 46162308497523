import { Container, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import classes from "./liveStream.module.scss";

export default function LiveStream() {
  const { t } = useTranslation();
  return (
    <Container className={classes.liveContainer}>
      <Typography variant="h6" className={classes.liveText}>
        {t("live.iciLive")}
      </Typography>
      <ReactPlayer
        className={classes.livePlayer}
        url="https://amdici.akamaized.net/hls/live/873426/ICI-Live-Stream/master.m3u8"
        controls={true}
        preview="true"
      />
      <Typography className={classes.text}>{t("live.iciDes")}</Typography>
    </Container>
  );
}
