import { ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedVideo } from "../redux/reducer";
import classes from "./gridList.module.scss";

export default function SingleLineGridList() {
  const dispatch = useDispatch();
  const archive = useSelector((state) => state.archive.archive);
  const selectedVideo = useSelector((state) => state.archive.selectedVideo);
  const [cols, setCols] = useState(0);

  const handleResize = () => {
    const screenWidth = window.innerWidth;
    let newCols;
    if (screenWidth < 600) newCols = 2;
    else if (screenWidth < 900) newCols = 3;
    else if (screenWidth < 1300) newCols = 4;
    else if (screenWidth >= 1300) newCols = 5;
    setCols(newCols);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    !!cols && (
      <ImageList className={classes.gridList} cols={cols}>
        {archive.data.map((video) => {
          return video.uri === selectedVideo.uri ? null : (
            <ImageListItem
              key={video.uri}
              onClick={() => dispatch(setSelectedVideo(video))}
            >
              <img
                src={video.pictures.sizes[2].link}
                alt={video.name}
                loading="lazy"
              />
              <ImageListItemBar
                title={video.name}
                classes={{
                  root: classes.titleBar,
                  title: classes.title,
                }}
              />
            </ImageListItem>
          );
        })}
      </ImageList>
    )
  );
}
