import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import classes from "./App.module.scss";
import Footer from "./components/footer";
import Header from "./components/header";
import SocialMedias from "./components/socialMedias";
import { translatePath } from "./services/utils";
import Archive from "./view/archive";
import ContactUs from "./view/contactUs";
import Home from "./view/home";
import Hosts from "./view/hosts";
import LiveStream from "./view/liveStream";

function App() {
  const { i18n } = useTranslation();
  const windowSize = useSelector((state: any) => state.general.windowSize);
  const language = i18n.language.slice(0, 2);
  
  return (
    <Container className={classes.appContainer}>
      <Header />
      { windowSize.width > 960 && <SocialMedias position="body" />}
      <Box className={classes.bodyContainer}>
        <Routes>
          <Route path={translatePath("home", language)} element={<Home />} />
          <Route
            path={translatePath("archive", language)}
            element={<Archive />}
          />
          <Route
            path={translatePath("contactus", language)}
            element={<ContactUs />}
          />
          <Route
            path={translatePath("iciLive", language)}
            element={<LiveStream />}
          />
          <Route path={translatePath("hosts", language)} element={<Hosts />} />
          <Route
            path="*"
            element={<Navigate to={translatePath("home", language)} />}
          />
        </Routes>
      </Box>
      <Footer />
    </Container>
  );
}

export default App;

