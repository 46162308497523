import { configureStore } from "@reduxjs/toolkit";
import generalReducer from "./generalReducer";
import archiveReducer from "./reducer";

const store = configureStore({
  reducer: {
    archive: archiveReducer,
    general: generalReducer,
  },
});

export default store;
