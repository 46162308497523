import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  archive: null,
  isLoading: null,
  isSuccess: null,
  selectedVideo: null,
  errorMessage: "",
};

export const fetchArchive = createAsyncThunk(
  "archive/fetchArchive",
  async (params = null, { rejectedWithValue }) => {
    try {
      let response;
      if (!params)
        response = await axios({ url: "/end-archive", method: "get" });
      else
        response = await axios({
          url: "/end-archive?" + params,
          method: "get",
        });
      return response.data.archive;
    } catch (err) {
      return rejectedWithValue(err.message);
    }
  }
);

export const archiveSlice = createSlice({
  name: "archive",
  initialState,
  reducers: {
    setSelectedVideo: (state, { payload }) => {
      state.selectedVideo = payload || state.archive.data[0];
    },
    setPage: (state, { payload }) => {
      state.archive = payload || state.archive.data[0];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchArchive.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchArchive.fulfilled, (state, { payload }) => {
        state.archive = payload;
        state.selectedVideo = payload.data[0];
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(fetchArchive.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      });
  },
});

export const { setSelectedVideo } = archiveSlice.actions;
export default archiveSlice.reducer;
