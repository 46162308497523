import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  Toolbar,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import translationEN from "../locales/en/translation.json";
import translationFR from "../locales/fr/translation.json";
import { setWindowSize } from "../redux/generalReducer";
import { translatePath } from "../services/utils";
import classes from "./header.module.scss";
import SocialMedias from "./socialMedias";

export default function Header() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [drawer, toggleDrawer] = useState(false);
  const windowSize = useSelector((state) => state.general.windowSize);
  const dispatch = useDispatch();
  const language = i18n.language.slice(0, 2);

  const handleChangeLang = () => {
    const path = Object.entries(
      language === "en" ? translationEN.routes : translationFR.routes
      // eslint-disable-next-line no-unused-vars
    ).find(([key, value]) => value === location.pathname);
    i18n.changeLanguage(i18n.language === "en" ? "fr" : "en");
    navigate(translatePath(path[0], i18n.language));
    if (drawer) toggleDrawer(false);
  };

  useEffect(() => {
    function handleResize() {
      dispatch(
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      );
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch]);

  const menuItems = (
    <>
      <NavLink
        className={(navdata) => (navdata.isActive ? classes.selectedMenu : "")}
        to={translatePath("home", language)}
        onClick={() => windowSize.width <= 960 && toggleDrawer((prev) => !prev)}
      >
        {t("menu.home")}
      </NavLink>
      <NavLink
        className={(navdata) => (navdata.isActive ? classes.selectedMenu : "")}
        to={translatePath("archive", language)}
        onClick={() => windowSize.width <= 960 && toggleDrawer((prev) => !prev)}
      >
        {t("menu.archive")}
      </NavLink>
      <NavLink
        className={(navdata) => (navdata.isActive ? classes.selectedMenu : "")}
        to={translatePath("hosts", language)}
        onClick={() => windowSize.width <= 960 && toggleDrawer((prev) => !prev)}
      >
        {t("menu.hosts")}
      </NavLink>
      <NavLink
        className={(navdata) => (navdata.isActive ? classes.selectedMenu : "")}
        to={translatePath("contactus", language)}
        onClick={() => windowSize.width <= 960 && toggleDrawer((prev) => !prev)}
      >
        {t("menu.contactus")}
      </NavLink>
      <NavLink
        className={(navdata) => (navdata.isActive ? classes.selectedMenu : "")}
        to={translatePath("iciLive", language)}
        onClick={() => windowSize.width <= 960 && toggleDrawer((prev) => !prev)}
      >
        {t("menu.iciLive")}
      </NavLink>
    </>
  );

  return (
    <AppBar
      className={classes.headerContainer}
      style={{ backgroundColor: "black", position: "sticky" }}
    >
      <Drawer
        anchor="left"
        PaperProps={{
          sx: {
            minWidth: "15rem",
            width: "50%",
            backgroundColor: "#e9f4ea",
            padding: "2rem 1rem",
            "& a, button": {
              margin: "1rem",
              textDecoration: "none",
              color: "#2c7b2c !important",
              fontSize: "1rem",
              fontWeight: "600",
              maxWidth: "fit-content",
              borderColor: "#2c7b2c !important",
            },
          },
        }}
        open={drawer}
        onClose={() => toggleDrawer(false)}
      >
        {menuItems}
        <SocialMedias position="drawer" />
        <Button
          onClick={handleChangeLang}
          variant="outlined"
          className={classes.languageBtn}
        >
          {t(`languageChangeFrom.${[language]}`)}
        </Button>
      </Drawer>
      <Toolbar className={classes.header}>
        <NavLink to="/home">
          <img
            className={classes.headerLogo}
            src="/ava-logo.svg"
            alt="logo"
          ></img>
        </NavLink>
        {windowSize.width <= 960 ? (
          <IconButton onClick={() => toggleDrawer(true)}>
            <MenuIcon className={classes.burgerMenu} />
          </IconButton>
        ) : (
          <Box className={classes.headerLinks}>
            {menuItems}
            <Button
              onClick={handleChangeLang}
              variant="outlined"
              className={classes.languageBtn}
            >
              {t(`languageChangeFrom.${[language]}`)}
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}
