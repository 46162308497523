import { Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import classes from "./footer.module.scss";

export default function Footer() {
  const { t } = useTranslation();
  return (
    <Typography className={classes.footerContainer}>
      {t("footer.rights")}
      <Link
        target="_blank"
        rel="noopener noreferrer"
        href="http://www.icitelevision.ca"
      >
        {t("footer.iciTelevision")}
      </Link>
    </Typography>
  );
}
