import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchArchive } from "../redux/reducer.js";
import classes from "./pagingBtns.module.scss";

export default function PagingBtns() {
  const { t } = useTranslation();
  const archive = useSelector((state) => state.archive.archive);
  const dispatch = useDispatch();

  return (
    <Box className={classes.btnsContainer}>
      <IconButton
        aria-label={t("archive.firtPage")}
        value={archive.paging.first}
        onClick={() => {
          dispatch(fetchArchive(archive.paging.first.split("?")[1]));
        }}
      >
        <FirstPageIcon />
      </IconButton>

      {archive.paging.previous && (
        <IconButton
          aria-label={t("archive.previousPage")}
          value={archive.paging.previous}
          onClick={() =>
            dispatch(fetchArchive(archive.paging.previous.split("?")[1]))
          }
        >
          <NavigateBeforeIcon />
        </IconButton>
      )}
      {archive.paging.next && (
        <IconButton
          aria-label={t("archive.nextPage")}
          value={archive.paging.next}
          onClick={() =>
            dispatch(fetchArchive(archive.paging.next.split("?")[1]))
          }
        >
          <NavigateNextIcon />
        </IconButton>
      )}
      <IconButton
        aria-label={t("archive.lastPage")}
        value={archive.paging.last}
        onClick={() =>
          dispatch(fetchArchive(archive.paging.last.split("?")[1]))
        }
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}
