import { Link, Typography } from "@mui/material";
import classes from "./socialMedias.module.scss";

// eslint-disable-next-line react/prop-types
export default function SocialMedias({ position }) {
  return (
    <Typography
      className={
        position === "drawer"
          ? classes.socialMediaDrawer
          : classes.socialMediaContainer
      }
    >
      <Link
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.facebook.com/avatelevision"
      >
        <img className={classes.socialMediasIcons} src="/facebook.svg" alt="" />
      </Link>
      <Link
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/avatelevision"
      >
        <img
          className={classes.socialMediasIcons}
          src="/instagram.svg"
          alt=""
        />
      </Link>
      <Link
        target="_blank"
        rel="noopener noreferrer"
        href="https://vimeo.com/avatelevision"
      >
        <img className={classes.socialMediasIcons} src="/vimeo.svg" alt="" />
      </Link>
      <Link
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.youtube.com/channel/UCyxmfR6K5MZaTBhjHqshwlg"
      >
        <img className={classes.socialMediasIcons} src="/youtube.svg" alt="" />
      </Link>
    </Typography>
  );
}
