import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  windowSize: { height: null, width: null },
  contactus: {
    isLoading: null,
    isSuccess: null,
    errorMessage: "",
    attempts: 0,
  },
  hosts: {
    isLoading: null,
    isSuccess: null,
    hosts: null,
    errorMessage: "",
  },
};

export const fetchHosts = createAsyncThunk(
  "general/fetchHosts",
  async (_, { rejectWithValue }) => {
    try {
      let response = await axios({
        url: "/end-hosts",
        method: "get",
      });
      return response.data.hosts;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const submitContactForm = createAsyncThunk(
  "general/submitContact",
  async (contact, { rejectWithValue }) => {
    try {
      let data = new FormData();
      data.append("contact", JSON.stringify(contact));
      const response = await axios({
        url: "/end-contactus",
        data,
        method: "post",
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.msg || err.message);
    }
  }
);

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setWindowSize: (state, { payload }) => {
      state.windowSize = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHosts.pending, (state) => {
        state.hosts.isLoading = true;
      })
      .addCase(fetchHosts.fulfilled, (state, { payload }) => {
        state.hosts.hosts = payload;
        state.hosts.isLoading = false;
        state.hosts.isSuccess = true;
      })
      .addCase(fetchHosts.rejected, (state, payload) => {
        state.hosts.isLoading = false;
        state.hosts.isSuccess = false;
        state.hosts.errorMessage = payload;
      })
      .addCase(submitContactForm.pending, (state) => {
        state.contactus.isLoading = true;
      })
      .addCase(submitContactForm.fulfilled, (state) => {
        state.contactus.isLoading = false;
        state.contactus.isSuccess = true;
        state.contactus.errorMessage = "";
      })
      .addCase(submitContactForm.rejected, (state, { payload }) => {
        state.contactus.isLoading = false;
        state.contactus.isSuccess = false;
        state.contactus.errorMessage = payload;
        state.contactus.attempts = state.contactus.attempts + 1;
      });
  },
});

export const { setWindowSize } = generalSlice.actions;
export default generalSlice.reducer;
