import React from "react";
import { useSelector } from "react-redux";
import { Box, CardMedia } from "@mui/material";
import classes from "./streamVideo.module.scss";

export default function SteamVideo() {
  const selectedVideo = useSelector((state) => state.archive.selectedVideo);
  return (
    <Box className={classes.playerContainer}>
      {selectedVideo && (
        <CardMedia
          component="iframe"
          src={`https://player.vimeo.com/video/${
            selectedVideo.uri.split("/videos/")[1]
          }`}
          className={classes.player}
          allow="autoplay; fullscreen"
          title={selectedVideo.title}
        />
      )}
    </Box>
  );
}
