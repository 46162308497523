import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchArchive } from "../redux/reducer.js";
import classes from "./filterBar.module.scss";

const sort = ["alphabetical", "date", "duration"];
const initialState = {
  query: "",
  sort: "",
  direction: "",
  page: "",
};

export default function FilterBar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [filterTerms, setFilters] = useState(initialState);
  const archive = useSelector((state) => state.archive.archive);
  const prevDefault = (e) => {
    e.preventDefault();
    handleFilter();
  };
  const handleFilter = () => {
    let activeFilters = {
      ...filterTerms,
      query: filterTerms.query ? `"${filterTerms.query}"` : filterTerms.query,
    };

    activeFilters = Object.keys(activeFilters).reduce((p, c) => {
      if (activeFilters[c]) p[c] = activeFilters[c];
      return p;
    }, {});

    dispatch(fetchArchive(new URLSearchParams(activeFilters)));
  };

  return (
    <form onSubmit={prevDefault} className={classes.filterBarContainer}>
      <TextField
        className={classes.filterItem}
        placeholder={t("archive.search")}
        color="success"
        variant="standard"
        type="text"
        value={filterTerms.query}
        onChange={(e) => setFilters({ ...filterTerms, query: e.target.value })}
      />
      <FormControl variant="standard" color="success">
        <Select
          className={classes.filterItem}
          displayEmpty
          value={filterTerms.sort}
          onChange={(e) => setFilters({ ...filterTerms, sort: e.target.value })}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>{t("archive.sort")}</em>;
            }

            return selected;
          }}
        >
          {sort.map((selectedSort, i) => (
            <MenuItem value={selectedSort} key={i}>
              {t(`archive.${[selectedSort]}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="standard" color="success">
        <Select
          className={classes.filterItem}
          displayEmpty
          type="Number"
          value={filterTerms.direction}
          onChange={(e) =>
            setFilters({ ...filterTerms, direction: e.target.value })
          }
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>{t("archive.sortDirection")}</em>;
            }

            return selected;
          }}
        >
          <MenuItem value="asc">{t("archive.asc")}</MenuItem>
          <MenuItem value="desc">{t("archive.desc")}</MenuItem>
        </Select>
      </FormControl>
      <TextField
        className={classes.filterItem}
        color="success"
        variant="standard"
        placeholder={t("archive.pageNo")}
        type="Number"
        InputProps={{
          inputProps: {
            max: archive.total / archive.per_page,
            min: 1,
          },
        }}
        value={filterTerms.page}
        onChange={(e) => setFilters({ ...filterTerms, page: e.target.value })}
      />
      <Button
        className={classes.filterItem}
        type="submit"
        variant="contained"
        color="success"
        size="small"
      >
        {t("archive.applyFilters")}
      </Button>
      <Button
        className={classes.filterItem}
        variant="outlined"
        color="success"
        size="small"
        onClick={() => {
          setFilters(initialState);

          dispatch(fetchArchive());
        }}
      >
        {t("archive.reset")}
      </Button>
    </form>
  );
}
